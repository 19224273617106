import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

// API-Schlüssel und List-ID
const API_KEY = process.env.REACT_APP_BREVO_API_KEY;
const LIST_ID = 19;
const WINNER_POSITIONS = [
  4512, 5145, 5502, 11762, 15403, 15448, 16247, 17293, 17306, 17475, 19312,
  21386, 22283, 24636, 24833, 24965, 28014, 28060, 28283, 28666, 29255, 30162,
  30803, 31551, 31620, 33621, 36641, 37201, 37602, 38391, 39096, 40870, 41364,
  44227, 45573, 46137, 48423, 48669, 49624,
];
const LIMIT = 500; // Maximale Anzahl von Kontakten pro Anfrage

// Funktion, um die Teilnehmerliste in Batches zu holen und nach Contact-ID zu sortieren
const getAllParticipants = async () => {
  try {
    let allContacts = [];
    let offset = 0;
    let totalItems = 0;

    do {
      const response = await axios.get(
        `https://api.brevo.com/v3/contacts/lists/${LIST_ID}/contacts`,
        {
          headers: {
            "api-key": API_KEY,
          },
          params: {
            limit: LIMIT,
            offset: offset,
          },
        }
      );

      const data = response.data;
      totalItems = data.totalItems; // Gesamtanzahl der Teilnehmer
      allContacts = [...allContacts, ...data.contacts];
      offset += LIMIT;
    } while (allContacts.length < totalItems);

    // Sortiere nach Contact-ID aufsteigend (kleinste ID zuerst)
    allContacts.sort((a, b) => a.id - b.id);

    return allContacts;
  } catch (error) {
    console.error("Error fetching participants:", error);
    return null;
  }
};

// Funktion, um den Gewinnstatus und ggf. den Ländercode nur für den aktuellen Kontakt zu speichern
const updateCurrentUserContact = async (email, country, isWinner) => {
  const data = {
    attributes: {
      ISWINNER: isWinner ? "true" : "false",
      ...(country && { COUNTRY: country }), // Ländercode nur setzen, wenn er vorhanden ist
    },
  };

  try {
    await axios.put(`https://api.brevo.com/v3/contacts/${email}`, data, {
      headers: {
        "api-key": API_KEY,
        "Content-Type": "application/json",
      },
    });
    console.log(
      `Updated contact ${email} with winner status: ${isWinner} and country: ${country}`
    );
  } catch (error) {
    console.error("Failed to update the contact:", error);
  }
};

// Funktion zur Gewinnerermittlung und Kontaktaktualisierung
const markWinnersAndUpdateCurrentUser = async (email, country) => {
  try {
    const contacts = await getAllParticipants();
    if (!contacts) return false;

    let userIsWinner = false;

    // Gewinnerstatus prüfen und nur für den aktuellen Kontakt speichern
    for (let i = 0; i < contacts.length; i++) {
      const currentPosition = i + 1;
      const contact = contacts[i];
      const isWinner = WINNER_POSITIONS.includes(currentPosition);

      // Wenn der aktuelle Kontakt der Benutzer ist, speichere den Ländercode und Gewinnstatus
      if (contact.email === email) {
        await updateCurrentUserContact(email, country, isWinner);
        userIsWinner = isWinner;
      }
    }

    return userIsWinner;
  } catch (error) {
    console.error("Error marking winners and updating contact:", error);
    return false;
  }
};

// Funktion, um die Query-Parameter zu holen
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Confirmation = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const emailFromUrl = query.get("email");
  const countryFromUrl = query.get("country"); // Ländercode aus der URL

  const [email, setEmail] = useState(emailFromUrl || ""); // E-Mail Zustand
  const [country, setCountry] = useState(countryFromUrl || ""); // Ländercode Zustand
  const [isWinner, setIsWinner] = useState(null); // Gewinnerstatus
  const [error, setError] = useState(""); // Fehlerstatus
  const [isChecking, setIsChecking] = useState(false); // Status der Prüfung

  // Funktion zur Überprüfung des Gewinnstatus und der Aktualisierung des Kontakts
  const handleCheckWin = useCallback(async (event) => {
    event.preventDefault(); // Verhindert das Neuladen der Seite

    if (!email || !country) {
      setError("Please provide a valid e-mail address and country code.");
      return;
    }

    setError("");
    setIsChecking(true);

    try {
      // Update URL with email and country
      navigate(`/confirmation?email=${encodeURIComponent(email)}&country=${encodeURIComponent(country)}`);

      // Trigger reCAPTCHA v3 and get token
      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute(
          "6LcemG8qAAAAAEqc8rp0et3eXNDIrBv2ngj3C4nO",
          { action: "submit" }
        );

        if (!token) {
          setError("reCAPTCHA verification failed. Please try again.");
          setIsChecking(false);
          return;
        }

        // Proceed with your logic (no backend to verify reCAPTCHA, but you can log the score in production)
        console.log("reCAPTCHA token:", token);

        // Gewinne markieren und Kontakt aktualisieren
        const isUserWinner = await markWinnersAndUpdateCurrentUser(
          email,
          country
        );
        setIsWinner(isUserWinner); // Setze den Gewinnstatus
      });
    } catch (error) {
      setError("An error has occurred. Please try again later.");
    }

    setIsChecking(false);
  }, [email, country, navigate]);

  // Automatische Prüfung bei Seitenaufruf
  useEffect(() => {
    if (emailFromUrl && countryFromUrl) {
      handleCheckWin();
    }
  }, [emailFromUrl, countryFromUrl, handleCheckWin]);

  return (
    <section className="registration">
      {/* E-Mail-Input-Field */}
      <form onSubmit={handleCheckWin}>
        <div className="inputControl">
          <i className="inputIcon">
            <img src="../images/icon--mail.svg" alt="eMail Icon" />
          </i>
          <label htmlFor="email">E-mail address</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Insert your e-mail address"
            required
          />
        </div>

        <div className="inputControl">
          <i className="inputIcon">
            <img src="../images/icon--europe.svg" alt="Select Country" />
          </i>
          <label htmlFor="country">Select your country</label>
          <input
            type="text"
            id="country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            placeholder="Insert your country code (DE, FR, IT, etc.)"
            required
          />
        </div>

        <button
          className="buttonControl"
          type="submit"
          disabled={isChecking}
        >
          {isChecking ? "Loading..." : "Check status"}
        </button>
      </form>

      {/* Fehlermeldung anzeigen */}
      {error && (
        <div className="alert alert--info">
          <div className="alert--inner">{error}</div>
        </div>
      )}

      {/* Gewinnstatus anzeigen */}
      {isWinner === null && !isChecking && (
        <div className="alert alert--info">
          <div className="alert--inner">
            <h2 className="alert-title">Check your current status</h2>
            <p className="alert-description">
              Please enter your current e-mail address and country to check your
              status.
            </p>
          </div>
        </div>
      )}
      {isWinner === true && (
        <div className="alert alert--success">
          <div className="alert--inner">
            <h2 className="alert-title">You won!</h2>
            <p className="alert-description">
              We are super happy for you! Our team will contact you shortly!
            </p>
          </div>
        </div>
      )}
      {isWinner === false && (
        <div className="alert alert--danger">
          <div className="alert--inner">
            <h2 className="alert-title">You lost!</h2>
            <p className="alert-description">
              So sorry, not this time! We will inform you about future offers!
            </p>
          </div>
        </div>
      )}
    </section>
  );
};

export default Confirmation;