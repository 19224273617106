import React from 'react';
import '../scss/_navigation.scss';

const Navigation = () => {
  return (
    <nav className="navigation">
      <div className="navigation--cta">
        <a className=" buttonControl buttonControl--outlined" href="https://www.worldofhanszimmer.com/" target="_blank" title="Back to Homepage" rel="noreferrer">Back to offical Website</a>
      </div>
      <a className="navigation--logo" href="/" title="Start"><img src="../images/logo.svg" alt="The World of Hans Zimmer Logo"/></a>
      <ul className="navigation--bar">
        <li><a href="#optin">Enter Code and Win</a></li>
        <li><a href="#prices">What you can win</a></li>
        <li><a href="#legal">Terms and Conditions</a></li>
      </ul>
    </nav>
  );
};

export default Navigation;