import React, { useState } from "react";
import Modal from "react-modal";
import productsData from "../data/products.json"; // Importiere die JSON-Daten
import "../scss/_products.scss";

Modal.setAppElement("#root");

const Products = () => {
  // Zustand für das aktuell ausgewählte Produkt
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Modal öffnen und das ausgewählte Produkt setzen
  const openModal = (product) => {
    setSelectedProduct(product);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedProduct(null);
  };

  return (
    <section className="products" id="prices">
      <h2>What can you win?</h2>

      {/* Mappe über die Preise im JSON und zeige sie an */}
      {productsData.prizes.map((product) => (
        <div
          className="card is-style-modal"
          key={product.id}
          onClick={() => openModal(product)}
          style={{
            backgroundImage: `url(${product.bgImage})`,
          }}
        >
          <img
            className="card--image"
            src={product.thumbnail}
            alt={product.title}
          />
          <div className="card--inner">
            <h3>{product.title}</h3>
          </div>
        </div>
      ))}

      {/* Modal für das ausgewählte Produkt */}
      {selectedProduct && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel={selectedProduct.title}
        >
          <div className="card-modal">
            <button onClick={closeModal}>back to the raffle</button>

            <figure className="card-modal--image">
              <img
                src={selectedProduct.thumbnail}
                alt={selectedProduct.title}
              />
            </figure>
            <div className="card-modal--inner">
              <h2>{selectedProduct.title}</h2>
              <p>Available quantity: {selectedProduct.quantity}</p>
            </div>
          </div>
        </Modal>
      )}
    </section>
  );
};

export default Products;
