import React from 'react';
import Navigation from '../components/Navigation';
import Stage from '../components/StageConfirmation';
import Confirmation from '../components/Confirmation';
import Legal from '../components/Legal';

const LandingPage = () => {
  return (
    <div id="ConfirmationPage">
      {/* Navigation Bar */}
      <Navigation />

      {/* Stage Section (Willkommensbereich mit Headline und Hintergrundbild) */}
      <Stage />

      {/* Registrierung Formular */}
      <Confirmation />

      {/* Rechtliche Informationen */}
      <Legal />
    </div>
  );
};

export default LandingPage;