import React, { useEffect, useState } from "react";
import "../scss/_stage.scss";

const Stage = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const progress = Math.min(scrollY / windowHeight, 1);
      setScrollPosition(progress);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="stage">
      <div className="stage--content">
        <span
          className="first-line"
          style={{
            transform: `translate3d(${scrollPosition * 300}px, 0, 0)`,
          }}
        >
          WIN
        </span>

        <span
          className="second-line"
          style={{
            transform: `translate3d(${scrollPosition * -300}px, 0, 0)`,
          }}
        >
          Tickets
        </span>
      </div>
    </section>
  );
};

export default Stage;
