import React, { useState } from "react";
import productsData from "../data/products.json"; // Importiere die JSON-Daten
import "../scss/_registration.scss";

// Länderdaten für die Select-Box
const countryOptions = [
  { value: "DE", label: "Germany" },
  { value: "LT", label: "Lithuania" },
  { value: "LV", label: "Latvia" },
  { value: "EE", label: "Estonia" },
  { value: "SE", label: "Sweden" },
  { value: "SK", label: "Slovakia" },
  { value: "HU", label: "Hungary" },
  { value: "NL", label: "Netherlands" },
  { value: "BE", label: "Belgium" },
  { value: "CH", label: "Switzerland" },
  { value: "FR", label: "France" },
  { value: "ES", label: "Spain" },
  { value: "PT", label: "Portugal" },
];

const Registration = () => {
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState(""); // Zustand für das Land
  const [dsgvoAccepted, setDsgvoAccepted] = useState(false);
  const [error, setError] = useState("");
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  // Code validieren (jetzt als String)
  const validateCode = () => {
    if (code.trim().toUpperCase() !== productsData.winningCode.toUpperCase()) {
      setError("The code you are entered is not valid");
      setIsCodeValid(false);
    } else {
      setError("");
      setIsCodeValid(true);
    }
  };

  // Keypress Event abfangen, um Enter zu überprüfen
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Verhindert, dass das Formular abgesendet wird
      validateCode(); // Code validieren
    }
  };

  return (
    <section className="registration" id="optin">
      <form
        id="sib-form"
        action="https://d8a346e3.sibforms.com/serve/MUIFABy8yRrKI3gsgrVcF98wZe0vpGvfYMfhNlRuDHQIxbHDYHpXsZl8cj4hdzuZxvWl1chojKpH-9nUvalA6Ox7iS9fvyJrc9pPpFCW40LFyjCNrPjkl874kXbn3DKhVDydPW7_8PfgKjAPfxg_X6BOoZOypzxi-gBOa66T3Yd1kUDUEC8LhM5Ui9BqTNmr-VJU4owjdR3bu1ye"
        method="POST"
      >
        <div className="inputControl">
          <i className="inputIcon">
            <img src="../images/icon--code.svg" alt="Enter Code Icon" />
          </i>
          <label htmlFor="code">Enter your code</label>
          <input
            type="text"
            id="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Type In Your Code"
            required
            disabled={isCodeValid}
          />
          <button
            type="button"
            onClick={validateCode}
            className={
              isCodeValid ? "is-style-check buttonControl" : "buttonControl"
            }
          >
            Enter ↵
          </button>
        </div>
        {/* Nur anzeigen, wenn der Code validiert ist */}
        {isCodeValid && (
          <>
            {/* Country Select-Box */}
            <div className="selectControl">
              <i className="inputIcon">
                <img src="../images/icon--europe.svg" alt="Select Country" />
              </i>
              <label htmlFor="country">Select your country</label>
              <select
                id="COUNTRYLIST"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                required
                name="COUNTRYLIST"
              >
                <option value="">Choose your country</option>
                {countryOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="inputControl">
              <i className="inputIcon">
                <img src="../images/icon--mail.svg" alt="eMail Icon" />
              </i>
              <label htmlFor="email">E-mail address</label>
              <input
                type="email"
                id="EMAIL"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-mail address"
                required
                name="EMAIL"
              />
            </div>
            <div className="checkboxControl">
              <input
                type="checkbox"
                checked={dsgvoAccepted}
                onChange={(e) => setDsgvoAccepted(e.target.checked)}
                required
              />
              <label>
                By entering your email, you agree to receive our newsletter and
                consent to the use of your data for the processing of the
                giveaway. You can unsubscribe at any time. Your information will
                not be shared with third parties. Terms and conditions apply.
              </label>
            </div>
          </>
        )}
        {/* Fehlermeldung anzeigen */}
        {error && (
          <div className="alert alert--info">
            <div className="alert--inner">{error}</div>
          </div>
        )}
        {/* Der Anmelden-Button wird erst nach erfolgreicher Code-Validierung angezeigt */}
        {isCodeValid && (
          <button className="buttonControl" type="submit" form="sib-form">
            Submit and win
          </button>
        )}
        {/* Erfolgsmeldung nach dem Absenden */}
        {successMessage && (
          <div className="alert alert--success">
            <div className="alert--inner">{successMessage}</div>
          </div>
        )}
      </form>
    </section>
  );
};

export default Registration;
