import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage'; // Die neue LandingPage
import ConfirmationPage from './pages/ConfirmationPage'; // Die Bestätigungsseite
import './styles.scss'; // Import der globalen Styles

function App() {
  return (
    <Router>
      <div className="App">
        {/* React Router für die verschiedenen Seiten */}
        <Routes>
          {/* LandingPage Route */}
          <Route path="/" element={<LandingPage />} />
          
          {/* ConfirmationPage Route */}
          <Route path="/confirmation" element={<ConfirmationPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;