import React from 'react';
import Navigation from '../components/Navigation';
import Stage from '../components/StageRegistration';
import Registration from '../components/Registration';
import Products from '../components/Products';
import Legal from '../components/Legal';

const LandingPage = () => {
  return (
    <div id="LandingPage">
      {/* Navigation Bar */}
      <Navigation />

      {/* Stage Section (Willkommensbereich mit Headline und Hintergrundbild) */}
      <Stage />

      {/* Registrierung Formular */}
      <Registration />

      {/* Produkte Teaser (mit Modal zur Anzeige der Preise) */}
      <Products />

      {/* Rechtliche Informationen */}
      <Legal />
    </div>
  );
};

export default LandingPage;